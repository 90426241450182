import React from "react";

// Components
import { H2, Text } from "@lewisblackwood/component-library";
import {
  ImageTextSection,
  TextSection,
  GridImageSection,
  CircleBackground
} from "../../layout";
import { ImageAnimated } from "../../../styledComponents/product/Image";

// images
import Checklist from "../../../images/product/checklist.svg";
import TaskTemplateCard from "../../../images/product/personably-task-template-card.svg";

const WelcomeDashFeatures = () => (
  <>
    <ImageTextSection style={{ marginTop: 40 }}>
      <GridImageSection
        columns="1fr auto auto auto 1fr"
        rows="1fr auto auto auto 1fr"
      >
        <ImageAnimated fitMobile src={Checklist} column="3 / 3" row="3 / 3" />
        <CircleBackground colour="pink" column="2 / 5" row="2 / 5" />
      </GridImageSection>

      <TextSection>
        <H2 size="xlarge" sizeMobile="large" colour="pink">
          Set hires to-dos for before and after they join
        </H2>
        <Text size="xlarge" sizeMobile="large">
          Help your hires to be fully prepared for their first day and their
          first few months by giving them a checklist.
        </Text>
      </TextSection>
    </ImageTextSection>

    <ImageTextSection>
      <TextSection>
        <H2 size="xlarge" sizeMobile="large" colour="pink">
          Give them relevant information before their first day
        </H2>
        <Text size="xlarge" sizeMobile="large">
          Access can be given before they start, even as soon as they accept the
          offer.
        </Text>
        <Text size="xlarge" sizeMobile="large">
          Content will update after their start date, staying relevant and
          helping them get up to speed.
        </Text>
      </TextSection>
      <GridImageSection
        columns="1fr auto auto auto 1fr"
        rows="1fr auto auto auto 1fr"
      >
        <ImageAnimated
          fitMobile
          src={TaskTemplateCard}
          column="3 / 3"
          row="3 / 3"
        />
        <CircleBackground colour="pink" column="2 / 5" row="2 / 5" />
      </GridImageSection>
    </ImageTextSection>
  </>
);

export default WelcomeDashFeatures;
