import React from "react";
import {
  Curve,
  ImageTextSection,
  TextSection,
  ImageSection,
  FillMargin
} from "../../layout";
import { ImageAnimated } from "../../../styledComponents/product/Image";
import { H2, Text } from "@lewisblackwood/component-library";

// Images
import HireSessions from "../../../images/product/hire-session-cards.svg";

const WelcomeDashIntro = () => (
  <>
    <Curve colour="pink" />
    <FillMargin colour="pink" />
    <ImageTextSection colour="pink" right>
      <TextSection>
        <H2 size="xlarge" sizeMobile="large">
          Hires can see which onboarding sessions they’ll have
        </H2>
        <Text size="xlarge" sizeMobile="large">
          Give your hires an idea of what their first few weeks and months are
          going to look&nbsp;like.
        </Text>
      </TextSection>
      <ImageSection>
        <ImageAnimated fitMobile src={HireSessions} />
      </ImageSection>
    </ImageTextSection>
    <FillMargin colour="pink" right />
    <Curve colour="pink" flipped />
  </>
);

export default WelcomeDashIntro;
