import React from "react";
import { Link } from "gatsby";
import {
  GridSection,
  Curve,
  ContentSection,
  FillMargin,
  TextSection
} from "../../layout";
import { H2, Text, TextLink } from "@lewisblackwood/component-library";
import { Avatar } from "../../../styledComponents/Common";

// Images
import Michael from "../../../images/profile-michael.jpg";

const SchedulingQuote = () => (
  <GridSection
    column="1 / 4"
    rows="repeat(5, auto)"
    fullWidth
    columns="minmax(16px, 1fr) auto minmax(16px, 1fr)"
  >
    <Avatar image={Michael} row="2" column="2" />
    <Curve colour="pink" row="1 / 4" column="1 / 4" />
    <FillMargin colour="pink" />
    <ContentSection colour="pink" row="4">
      <TextSection quote>
        <H2 center size="large" sizeMobile="default" mb="xxsmall">
          Michael
        </H2>
        <Text
          center
          bold
          size="xlarge"
          sizeMobile="large"
          mb="large"
          colour="black"
        >
          People and Talent Coordinator, carwow
        </Text>
        <Text center size="xlarge" sizeMobile="large" mb="large">
          &#8220;Those first few weeks are really key. Having a smooth entry
          into your job is the difference between settling well into that
          company or not, it means we know that person has access to the right
          information but more importantly at the right time.&#8221;
        </Text>
        <Text center>
          <Link to="/blog/how-carwow-provide-a-consistent-welcome-for-every-new-hire">
            <TextLink colour="pink" bold size="large">
              Read carwow’s story
            </TextLink>
          </Link>
        </Text>
      </TextSection>
    </ContentSection>
    <FillMargin colour="pink" right />
    <Curve colour="pink" flipped row="5" column="1 / 4 " />
  </GridSection>
);

export default SchedulingQuote;
