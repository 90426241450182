import React from "react";
import { ImageTextSection, TextSection, ImageSection } from "../../layout";

import { Image } from "../../../styledComponents/product/Image";
import { H1, H2, Text } from "@lewisblackwood/component-library";

// Images
import WelcomeDash from "../../../images/product/hire-dash.svg";

const Hero = () => (
  <ImageTextSection>
    <ImageSection>
      <Image src={WelcomeDash} fit />
    </ImageSection>
    <TextSection hero>
      <H1
        size="small"
        sizeMobile="small"
        mb="xsmall"
        colour="grey"
        style={{ fontWeight: 400 }}
      >
        Welcome Dashboard
      </H1>
      <H2 size="xxlarge" sizeMobile="large">
        Engage your hires from before they start
      </H2>
      <Text size="xlarge" sizeMobile="large">
        Remove some of the stress and uncertainty that comes with starting a new
        role. Let your hires know what to expect from their first few months.
      </Text>
    </TextSection>
  </ImageTextSection>
);

export default Hero;
