import React from "react";
import Layout from "../../components/layout";
import Helmet from "react-helmet";

// Components
import Hero from "../../components/product/welcome-dashboard/Hero";
import WelcomeDashIntro from "../../components/product/welcome-dashboard/WelcomeDashIntro";
import WelcomeDashFeatures from "../../components/product/welcome-dashboard/WelcomeDashFeatures";
import WelcomeDashQuote from "../../components/product/welcome-dashboard/WelcomeDashQuote";

const WelcomeDash = () => (
  <Layout>
    <Helmet>
      <title>Hire Dashboard</title>
      <meta
        name="description"
        content="Remove some of the stress and uncertainty that comes with starting a new role. Let your hires know what to expect from their first few months!"
      />
    </Helmet>
    <Hero />
    <WelcomeDashIntro />
    <WelcomeDashFeatures />
    <WelcomeDashQuote />
  </Layout>
);

export default WelcomeDash;
